
import { Prop, Vue, Component } from 'vue-property-decorator'
import { Palette } from '@/models/dto/Palette'
import { Status } from '@/models/dto/Status'

@Component({})
export default class DetailWithSidebar extends Vue {
  @Prop({ default: '' })
  title!: string

  @Prop({ default: null })
  actions!: string[]

  @Prop({ default: null })
  status!: Status

  @Prop({ default: false, type: Boolean })
  displayLeftSidebar!: boolean

  @Prop({ default: false, type: Boolean })
  hideHeaderDivider!: boolean

  @Prop({ default: '400' })
  sidebarWidth!: string

  get quoteStatusColor(): Palette {
    return Status.color(this.status)
  }
}
