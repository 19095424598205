
import { Component, Vue, Provide } from 'vue-property-decorator'
import { Address } from '@/models/dto'
import { formatFullName } from '@/utils/string'
import client from '@/services/customer'
import dayjs from 'dayjs'
import DetailsContent from '@/components/DetailsContent.vue'
import DetailWithSidebar from '@/layouts/DetailWithSidebar.vue'
import CUUserCard from '@/components/CUUserCard.vue'
import { EventBus } from '@/utils/eventBus'
import { Note } from '@/models/dto/Note'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import ContactSidebarDetail from '@/components/ContactSidebarDetail.vue'
import { ContactRequest, Customer } from '@/models/dto/Customer'
import { NotificationsSetting } from '@/models/NotificationsSetting'

@Component({
  components: {
    CUUserCard,
    DetailsContent,
    DetailWithSidebar,
    AutocompleteAddress,
    ContactSidebarDetail,
  },
  metaInfo: {
    title: 'Contact Details',
  },
})
export default class ContactDetails extends Vue {
  contactId: number = null
  contact: Customer | null = null
  loading = false
  userHasBeenModified = false

  @Provide() isInContactDetailView = true

  get contactNote(): Note {
    return this.contact?.customerNotes?.[0]
  }

  get fullName(): string {
    return formatFullName(this.contact)
  }

  async handleSaveContact(): Promise<void> {
    this.loading = true
    const ref: any = this.$refs['contact-sidebar-detail']
    await ref.submit()
    this.loading = false
    this.userHasBeenModified = false
  }

  async handleCreateNote(note: Note): Promise<void> {
    this.contact.customerNotes = [note]
    const contactRequest = new ContactRequest(this.contact)
    contactRequest.address = null
    await client.update(this.contactId, contactRequest)
    this.load()
  }

  async handleUpdateSendQuotesAsInvoice(
    sendQuotesAsInvoice: boolean
  ): Promise<void> {
    this.contact.sendQuotesAsInvoice = sendQuotesAsInvoice
    const contactRequest = new ContactRequest(this.contact)
    contactRequest.address = null
    await client.update(this.contactId, contactRequest)
    this.load()
  }

  async handleNotificationsUpdate({
    defaultSendDriverOnTheWayEmail,
    defaultSendDriverInfoEmail,
  }: NotificationsSetting): Promise<void> {
    try {
      this.contact.notificationsSetting = {
        defaultSendDriverOnTheWayEmail,
        defaultSendDriverInfoEmail,
      }
      await client.updateNotificationsSetting(this.contactId, {
        defaultSendDriverOnTheWayEmail,
        defaultSendDriverInfoEmail,
      })
    } catch (e) {
      console.error(e)
      EventBus.$emit('notification:error', {
        message: 'An error occurred while updating notifications',
      })
    }
  }

  load(): void {
    this.contactId = this.$route.params?.id
      ? Number.parseInt(this.$route.params.id, 10)
      : null
    if (this.contactId) {
      client
        .byId(this.contactId)
        .then((res) => res.data)
        .then((user) => (this.contact = user.customer))
        .then(() => {
          if (!this.contact?.address) {
            this.contact.address = new Address()
          }
        })
    }
  }

  async mounted(): Promise<void> {
    EventBus.$on('note:create', this.handleCreateNote)
    EventBus.$on(
      'update:send-quotes-as-invoice',
      this.handleUpdateSendQuotesAsInvoice
    )
    EventBus.$on('update:notifications', this.handleNotificationsUpdate)
    EventBus.$on('contact-sidebar:update', this.load)

    await this.load()
    this.userHasBeenModified = false
  }

  beforeDestroy(): void {
    EventBus.$off('note:create', this.handleCreateNote)
    EventBus.$off(
      'update:send-quotes-as-invoice',
      this.handleUpdateSendQuotesAsInvoice
    )
    EventBus.$off('contact-sidebar:update', this.load)
    EventBus.$off('update:notifications', this.handleNotificationsUpdate)
  }
}
